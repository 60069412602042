<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="tableLoading">
        <div class="content-title auto">
          <div class="coustom-title fl">角色管理（{{total}}类）</div>
          <div class="coustom-title-mes fr">
            <a-icon type="info-circle" theme="filled" style="color:#1890FF;margin-right: 10px;" />系统一共有角色 {{total}} 类
          </div>
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addRoles"> 添加角色 </a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
            <span slot="action" slot-scope="text,record,index">{{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}</span>
            <div slot="accountid" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5" @click="editRoles(record)">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteUser(record)"
              >
                <a-button type="primary" size="small" class="m-r-5 bgred borred">删除</a-button>
              </a-popconfirm>
              <a-button size="small" @click="checkRoles(record)">查看</a-button>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addRoles"> 添加角色 </a-button>
            </div>
          </custom-page>
        </div>
      </a-spin>
    </a-layout-content>
    <a-modal v-model="addVisible" title="角色编辑" @cancel="addClose" @ok="addSubmit" width="900px" id="power-roles-dialog">
      <a-spin :spinning="editLoading">
        <a-form-model
            id="no-margin-form"
            layout="inline"
            ref="ruleForm"
            :model="form"
            :rules="rules"
            class="ant-advanced-search-form"
            labelAlign="left"
            :label-col="{span: 3}"
            :wrapper-col="{span: 21}"
          >
          <a-form-model-item label="角色名称" prop="name" style="width:100%;">
            <a-input v-model="form.name" placeholder="请输入名称" size="small" style="margin-left:-3%;width:103%;" />
          </a-form-model-item>
          <div class="add-btn m-t-10">
            <a-button type="dashed" style="width:100%;" @click="addItem">+ 添加权限</a-button>
          </div>
          <div class="add-item-box auto" :style="'height:' + domHeight + 'px;'">
            <div class="add-item" v-for="(item, index) in form.value" :key="index">
              <a-select
                show-search
                placeholder="请选择分类"
                v-model="form.value[index]['id']"
                option-filter-prop="children"
                style="width:16%;margin-right:5px;"
                allowClear
                size="small"
                class="custom-a-select"
                @change="changeOperation($event, index)"
              >
                <a-select-option :value="operationItem.id" v-for="(operationItem, operationIndex) in filters['operation']" :key="operationIndex" :disabled="operationItem.disabled">
                  {{operationItem.name}}
                </a-select-option>
              </a-select>
              <el-cascader placeholder="请选择操作类型"
                v-if="form.value[index]['id'] && filters['operation'].length"
                @change="changeUserOperation(form.value[index]['privilege'], index)"
                v-model="form.value[index]['privilege']"
                :show-all-levels="false"
                :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: true }"
                :options="filters['operation'][filters['operation'].findIndex(item => {return item.id === form.value[index]['id']})]['children']"
                :disabled="filters['operation'][filters['operation'].findIndex(item => {return item.id === form.value[index]['id']})]['children'] && filters['operation'][filters['operation'].findIndex(item => {return item.id === form.value[index]['id']})]['children'][0] && !filters['operation'][filters['operation'].findIndex(item => {return item.id === form.value[index]['id']})]['children'][0]['children']"
                size="mini"
                style="width:22%;margin-right:5px;"
                :class="form.value[index]['privilege'] && form.value[index]['privilege'].length > 0 ? 'custom-el-cascader-no-max' : 'custom-el-cascader'"
                clearable>
              </el-cascader>
              <el-cascader
                placeholder="请选择管理单位"
                v-if="form.value[index]['id'] && filters['operation'].length && filters['operation'][filters['operation'].findIndex(item => {return item.id === form.value[index]['id']})]['display_depart']"
                v-model="form.value[index]['depart']"
                :show-all-levels="false"
                :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', emitPath: false }"
                :options="filters['department']"
                size="mini"
                style="width:24%;margin-right:5px;"
                class="custom-el-cascader"
                clearable>
              </el-cascader>
              <el-cascader
                placeholder="请选择管理对象"
                v-if="form.value[index]['id'] && filters['operation'].length && filters['operation'][filters['operation'].findIndex(item => {return item.id === form.value[index]['id']})]['display_type']"
                v-model="form.value[index]['type']"
                :show-all-levels="false"
                :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: true, emitPath: false }"
                :options="filters['usertype']"
                size="mini"
                style="width:30%;margin-right:5px;"
                :class="form.value[index]['type'] && form.value[index]['type'].length > 0 ? 'custom-el-cascader-no-max' : 'custom-el-cascader'"
                clearable>
              </el-cascader>
              <a href="javascript:;" class="font16" @click="deleteItem(index)" v-if="form.value.length > 1"><a-icon type="delete" /></a>
            </div>
          </div>
        </a-form-model>
      </a-spin>
    </a-modal>
    <a-modal v-model="checkVisible" title="查看角色成员" :footer="null" @ok="checkClose" width="900px">
      <a-row>
        <a-col :span="24">角色名称：{{current_row.name}}</a-col>
      </a-row>
      <a-row class="m-t-10">
        <a-col :span="24">
          <a-table :loading="tableLoading" :rowKey="(record,index)=>{return index}" :columns="checkTableHeader" :data-source="checkTableData" size="middle" :pagination="false">
            <span slot="action" slot-scope="text,record,index">{{ index + (checkTableFilter.page_size * (checkTableFilter.current_page - 1)) + 1 }}</span>
            <div slot="department" slot-scope="text">
              <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
          </a-table>
        </a-col>
      </a-row>
      <div class="p-tb-10">
        <custom-page :total="checkTableCount" @getPageMes="getPageMes2" :page="checkTableFilter.current_page" :limit="checkTableFilter.page_size"></custom-page>
      </div>
    </a-modal>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      domHeight: 200,
      loading: false,
      filters: {},
      departmentName: {},
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          key: 'action'
        },
        {
          title: '角色名称',
          dataIndex: 'name'
        },
        // {
        //   title: '权限',
        //   dataIndex: 'department'
        // },
        {
          title: '操作',
          dataIndex: 'accountid',
          scopedSlots: { customRender: 'accountid' },
          customCell: () => {
            return {
              style: {
                'width': '205px',
              }
            }
          }
        }
      ],
      tabledata: [],
      total: 0,
      filter: {
        current_page: 1,
        page_size: 10
      },
      addVisible: false,
      mockData: [],
      targetKeys: [],
      form: {
        name: '',
        value: [
          {
            id: undefined,
            privilege: undefined,
            depart: undefined,
            type: undefined
          }
        ]
      },
      rules: {
        name: [
          { required: true, message: '', trigger: 'blur' },
          { min: 3, max: 5, message: '', trigger: 'blur' },
        ]
      },
      checkVisible: false,
      checkTableHeader: [
        {
          title: '序号',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' }
        }
      ],
      checkTableData: [],
      checkTableFilter: {
        current_page: 1,
        page_size: 10
      },
      checkTableCount: 0,
      current_row: {},
      isAdd: false,
      itemMaxLength: 2,
      editLoading: false,
      tableLoading: true,
      oldForm: {}
    }
  },
  watch: {
    'addVisible': function (val) {
      if (val) {
        this.oldForm = JSON.parse(JSON.stringify(this.form))
      }
    }
  },
  methods: {
    changeUserOperation (val, fromIndex) {
      console.log(JSON.stringify(this.form))
      let index = this.filters['operation'].findIndex((item) => {return item.id === 'user'})
      let userSearchIndex = this.filters['operation'][index]['children'][0]['children'].findIndex((item) => {return item.id === 'query'})
      // let userModifyIndex = this.filters['operation'][index]['children'][0]['children'].findIndex((item) => {return item.id === 'modify'})
      let userMgSearchIndex = this.filters['operation'][index]['children'][0]['children'].findIndex((item) => {return item.id === 'query_mg'})

      // console.log('oldForm', this.oldForm)
      let hasOldUserMgSearchIndex = -1
      if (this.oldForm.value[fromIndex]['privilege']) {
        hasOldUserMgSearchIndex = this.oldForm.value[fromIndex]['privilege'].findIndex((item) => {
          return JSON.stringify(item) === '["all","query_mg"]'
        })
      }

      let hasOldUserModify = -1
      if (this.oldForm.value[fromIndex]['privilege']) {
        hasOldUserModify = this.oldForm.value[fromIndex]['privilege'].findIndex((item) => {
          return JSON.stringify(item) === '["all","modify"]'
        })
      }

      let hasNewUserMg = this.form.value[fromIndex]['privilege'].findIndex((item) => {
        return JSON.stringify(item) === '["all","query_mg"]'
      })

      let hasNewUser = this.form.value[fromIndex]['privilege'].findIndex((item) => {
        return JSON.stringify(item) === '["all","query"]'
      })

      let hasNewUserModify = this.form.value[fromIndex]['privilege'].findIndex((item) => {
        return JSON.stringify(item) === '["all","modify"]'
      })

      // 选了人员查询(脱敏)
      if (hasOldUserMgSearchIndex < 0 && hasNewUserMg > -1) {
        console.log('选了人员查询(脱敏)')
        if (hasNewUserModify > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewUserModify, 1)
        }
        if (hasNewUser > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewUser, 1)
        }
        this.filters['operation'][index]['children'][0]['children'][userSearchIndex]['disabled'] = false
        this.filters['operation'][index]['children'][0]['children'][userMgSearchIndex]['disabled'] = true
      }
      // console.log(JSON.stringify(this.form))

      // 选了人员查询
      if (hasOldUserMgSearchIndex > -1 && hasNewUser > -1) {
        console.log('选了人员查询')
        if (hasNewUserMg > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewUserMg, 1)
        }
        this.filters['operation'][index]['children'][0]['children'][userSearchIndex]['disabled'] = true
        this.filters['operation'][index]['children'][0]['children'][userMgSearchIndex]['disabled'] = false
      }

      // 选了人员修改
      if (hasOldUserModify < 0 && hasNewUserModify > -1) {
        console.log('选了人员修改')
        this.filters['operation'][index]['children'][0]['children'][userSearchIndex]['disabled'] = true
        this.filters['operation'][index]['children'][0]['children'][userMgSearchIndex]['disabled'] = false
        if (hasNewUserMg > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewUserMg, 1)
        }
        for (let key in val) {
          if (val[key].indexOf('query') < 0) {
            this.form.value[fromIndex]['privilege'].push(['all', 'query'])
          }
        }
      }



      let hasOldAccountMg = -1
      if (this.oldForm.value[fromIndex]['privilege']) {
        hasOldAccountMg = this.oldForm.value[fromIndex]['privilege'].findIndex((item) => {
          return JSON.stringify(item) === '["all","account/query_mg"]'
        })
      }

      let hasOldAccount = -1
      if (this.oldForm.value[fromIndex]['privilege']) {
        hasOldAccount = this.oldForm.value[fromIndex]['privilege'].findIndex((item) => {
          return JSON.stringify(item) === '["all","account"]'
        })
      }

      let hasOldAccountModify = -1
      if (this.oldForm.value[fromIndex]['privilege']) {
        hasOldAccountModify = this.oldForm.value[fromIndex]['privilege'].findIndex((item) => {
          return JSON.stringify(item) === '["all","account/modify"]'
        })
      }

      let hasNewAccountMg = this.form.value[fromIndex]['privilege'].findIndex((item) => {
        return JSON.stringify(item) === '["all","account/query_mg"]'
      })

      let hasNewAccount = this.form.value[fromIndex]['privilege'].findIndex((item) => {
        return JSON.stringify(item) === '["all","account/query"]'
      })

      let hasNewAccountModify = this.form.value[fromIndex]['privilege'].findIndex((item) => {
        return JSON.stringify(item) === '["all","account/modify"]'
      })

      // console.log('hasOldAccountMg', hasOldAccountMg)
      // console.log('hasOldAccountModify', hasOldAccountModify)
      // console.log('hasNewAccountMg', hasNewAccountMg)
      // console.log('hasNewAccount', hasNewAccount)
      // console.log('hasNewAccountModify', hasNewAccountModify)

      let accountSearchIndex = this.filters['operation'][index]['children'][0]['children'].findIndex((item) => {return item.id === 'account/query'})
      // 选了账号查询(脱敏)
      if (hasOldAccountMg < 0 && hasNewAccountMg > -1) {
        console.log('选了账号查询(脱敏)')
        if (hasNewAccountModify > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewAccountModify, 1)
        }
        if (hasNewAccount > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewAccount, 1)
        }
      } else if (hasOldAccount < 0 && hasNewAccount > -1) { // 选了账号查询
        console.log('选了账号查询')
        if (hasNewAccountMg > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewAccountMg, 1)
        }
      } else if (hasOldAccountModify < 0 && hasNewAccountModify > -1) { // 选了账号修改
        console.log('选了账号修改')
        this.filters['operation'][index]['children'][0]['children'][accountSearchIndex]['disabled'] = true
        if (hasNewAccountMg > -1) {
          this.form.value[fromIndex]['privilege'].splice(hasNewAccountMg, 1)
        }
        for (let key in val) {
          if (val[key].indexOf('account/query') < 0) {
            this.form.value[fromIndex]['privilege'].push(['all', 'account/query'])
          }
        }
      }
      
      //hasOldAccountModify > -1 && 
      if (hasOldAccountModify < 0) { // 取消了账号修改
        console.log('取消了账号修改')
        this.filters['operation'][index]['children'][0]['children'][accountSearchIndex]['disabled'] = false
      }


      // let accountSearchIndex = this.filters['operation'][index]['children'][0]['children'].findIndex((item) => {return item.id === 'account/query'})
      // let hasAccountModify = false
      // for (let key in val) {
      //   if (val[key].indexOf('account/modify') > -1) {
      //     this.filters['operation'][index]['children'][0]['children'][accountSearchIndex]['disabled'] = true
      //     if (val[key].indexOf('account/query') < 0) {
      //       this.form.value[fromIndex]['privilege'].push(['all', 'account/query'])
      //     }
      //     hasAccountModify = true
      //     break
      //   }
      // }
      // if (!hasAccountModify) this.filters['operation'][index]['children'][0]['children'][accountSearchIndex]['disabled'] = false
      this.filters = JSON.parse(JSON.stringify(this.filters))
      this.form = JSON.parse(JSON.stringify(this.form))
      this.oldForm = JSON.parse(JSON.stringify(this.form))
      // console.log('this.form', this.form)
    },
    getPageMes (obj) {
      this.filter = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.getList()
    },
    getPageMes2 (obj) {
      this.checkTableFilter = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.getList()
    },
    editRoles (row) {
      console.log(row)
      this.current_row = row
      this.isAdd = false
      this.addVisible = true
      let form = {
        name: row.name,
        value: [
          // {
          //   id: undefined,
          //   privilege: undefined,
          //   depart: undefined,
          //   type: undefined
          // }
        ]
      }
      for (let key in row.authorization) {
        let item = row.authorization[key]
        if (key === 'user') {
          row.authorization[key].map(item2 => {
            let privilege = []
            if (item2.privilege === '*') {
              privilege = [['all']]
            } else {
              item2.privilege.map(item3 => {
                if (item3.indexOf('all') < 0) {
                  item3.unshift('all')
                }
                privilege.push(item3)
              })
            }
            form.value.push({
              id: key,
              privilege: privilege,
              depart: item2.scope.depart,
              type: item2.scope.type
            })
          })
        } else {
          let privilege = []
          if (item.privilege === '*') {
            privilege = [['all']]
          } else {
            item.privilege.map(item3 => {
              if (item3.indexOf('all') < 0) {
                item3.unshift('all')
              }
              privilege.push(item3)
            })
          }
          form.value.push({
            id: key,
            privilege: privilege,
            depart: undefined,
            type: undefined
          })
        }
      }
      this.form = form
      // console.log(this.form)
      this.initOperationDisabled()
    },
    initOperationDisabled ($event, index) {
      let userLength = 0
      let keys = []
      this.form.value.map(item => {
        if (item.id === 'user') {
          userLength++
        }
        let key = item.id
        keys.push(item.id)
      })
      if ($event === 'user') {
        let userSearchIndex = this.filters['operation'][index]['children'][0]['children'].findIndex((item) => {return item.id === 'query'})
        this.filters['operation'][index]['children'][0]['children'][userSearchIndex]['disabled'] = true
        console.log(this.form.value[index])
        this.form.value[index]['privilege'] = []
        this.form.value[index]['privilege'].push(['all', 'query'])
      }
      this.filters['operation'].map(item => {
        if (keys.indexOf(item.id) > -1) {
          item['disabled'] = true
        } else {
          item['disabled'] = false
        }
        if (item.id === 'user' && userLength >= 2) {
          item['disabled'] = true
        }
        if (item.id === 'user' && userLength < 2) {
          item['disabled'] = false
        }
      })
      this.filters = JSON.parse(JSON.stringify(this.filters))
      this.form = JSON.parse(JSON.stringify(this.form))
    },
    changeOperation ($event, index) {
      console.log($event, index)
      if (this.filters['operation'][this.filters['operation'].findIndex(item => {return item.id === this.form.value[index]['id']})]['children'] && this.filters['operation'][this.filters['operation'].findIndex(item => {return item.id === this.form.value[index]['id']})]['children'][0] && !this.filters['operation'][this.filters['operation'].findIndex(item => {return item.id === this.form.value[index]['id']})]['children'][0]['children']) {
        this.form.value[index]['privilege'] = [['all']]
      } else {
        this.form.value[index]['privilege'] = undefined
      }
      this.form.value[index]['depart'] = undefined
      this.form.value[index]['type'] = undefined
      this.initOperationDisabled($event, index)
      console.log(this.form)
      // this.form.value[index] = {
      //   id: $event,
      //   privilege: undefined,
      //   depart: undefined,
      //   type: undefined
      // }
    },
    checkRoles (row) {
      this.current_row = row
      this.checkVisible = true
      this.checkTableFilter = {
        current_page: 1,
        page_size: 10
      }
      this.getRoleUserList()
    },
    async getRoleUserList () {
      this.loading = true
      let res = await System.getRoleUserList(this.current_row.id, this.checkTableFilter)
      this.loading = false
      if (!res['code']) {
        this.checkTableData = res['data']
        this.checkTableCount = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    checkClose () {
      this.checkTableData = []
      this.checkTableCount = 0
      this.checkVisible = false
    },
    addClose () {
      this.filters['operation'].map(item => {
        if (item.disabled) {
          item.disabled = false
        }
      })
      this.form = {
        name: '',
        value: [
          {
            id: undefined,
            privilege: undefined,
            depart: undefined,
            type: undefined
          }
        ]
      }
      this.addVisible = false
      this.$refs['ruleForm'].resetFields()
    },
    addSubmit () {
      this.$refs['ruleForm'].validate( async validate => {
        if (validate) {
          let status = true
          for (let key in this.form.value) {
            let item = this.form.value[key]
            if (!item.id) {
              status = false
              break
            }
            if (!item.privilege || (item.privilege && item.privilege.length === 0)) {
              status = false
              break
            }
            let selection = this.filters['operation'].find(operation => {return operation.id === item.id})
            if (selection.display_type && (!item.type || !item.type.length)) {
              status = false
              break
            }
            if (selection.display_depart &&  (!item.depart || !item.depart.length)) {
              status = false
              break
            }
          }
          if (!status) {
            this.$message.error('请填写完整！')
            return false
          }
          let form = {
            name: this.form.name,
            authorization: {}
          }
          if (!this.isAdd) {
            form.id = this.current_row.id
          }
          this.form.value.map(item => {
            if (item.id === 'user' && !form.authorization.user) {
              form.authorization['user'] = []
              let isAllPrivilege = false
              for (let key in item.privilege) {
                if (item.privilege[key].length === 1 && item.privilege[key][0] === 'all') {
                  isAllPrivilege = true
                  break
                }
              }
              if (item.privilege && item.privilege.length) {
                item.privilege.map(item2 => {
                  item2.splice(item2.findIndex(item => item === 'all'), 1)
                })
              }
              form.authorization['user'].push({
                scope: {
                  type: item.type,
                  depart: item.depart
                },
                privilege: isAllPrivilege ? '*' : item.privilege
              })
            } else {
              if (item.id === 'user') {
                let isAllPrivilege = false
                for (let key in item.privilege) {
                  if (item.privilege[key].length === 1 && item.privilege[key][0] === 'all') {
                    isAllPrivilege = true
                    break
                  }
                }
                if (item.privilege && item.privilege.length) {
                  item.privilege.map(item2 => {
                    item2.splice(item2.findIndex(item => item === 'all'), 1)
                  })
                }
                form.authorization['user'].push({
                  scope: {
                    type: item.type,
                    depart: item.depart
                  },
                  privilege: isAllPrivilege ? '*' : item.privilege
                })
              } else {
                let isAllPrivilege = false
                for (let key in item.privilege) {
                  if (item.privilege[key].length === 1 && item.privilege[key][0] === 'all') {
                    isAllPrivilege = true
                    break
                  }
                }
                if (item.privilege && item.privilege.length) {
                  item.privilege.map(item2 => {
                    item2.splice(item2.findIndex(item => item === 'all'), 1)
                  })
                }
                form.authorization[item.id] = {
                  privilege: isAllPrivilege ? '*' : item.privilege
                }
              }
            }
          })
          let res = null
          this.editLoading = true
          if (this.isAdd) {
            res = await System.addAuthorizationRole(form)
          } else {
            res = await System.changeAuthorizationRole(this.current_row.id, form)
          }
          this.editLoading = false
          if (!res['code']) {
            this.addClose()
            this.getList()
            this.$message.success('操作成功！')
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
      // console.log(this.form.value)
    },
    deleteItem (index) {
      this.form.value.splice(index, 1)
      this.initOperationDisabled()
    },
    addItem () {
      this.form.value.unshift({
        id: undefined,
        privilege: undefined,
        depart: undefined,
        type: undefined
      })
    },
    addRoles () {
      this.isAdd = true
      this.addVisible = true
    },
    async deleteUser (row) {
      let res = await System.deleteAuthorizationRole(row.id)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getList () {
      this.tableLoading = true
      let res = await System.getAuthorizationRole(this.filter)
      this.tableLoading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.total = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    async getAuthorizationCode () {
      let res = await System.getAuthorizationCode('department')
      this.filters['department'] = res['data']
      this.trunDepartmentData(res['data'])
      let res1 = await System.getAuthorizationCode('usertype')
      this.filters['usertype'] = res1['data']
      let res2 = await System.getAuthorizationCode('role')
      this.filters['role'] = res2['data']
      let res3 = await System.getAuthorizationCode('operation')
      let options = []
      res3['data'].map(item => {
        let newItem = JSON.parse(JSON.stringify(item))
        newItem['children'] = []
        newItem['children'][0] = {
          id: 'all',
          name: '全部',
          children: item['children']
        }
        options.push(newItem)
      })
      this.filters['operation'] = options
      this.getList()
      // this.filters['operation'] = res3['data']
      // this.filters['operation'] = [
      //   {
      //     "id": "user",
      //     "name": "人员管理",
      //     "category": "serviceType",
      //     "multiple": true,
      //     "display_type": true,
      //     "display_depart": true,
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "add",
      //             "name": "新增/导入"
      //           },
      //           {
      //             "id": "remove",
      //             "name": "删除"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改",
      //             "children": [
      //               {
      //                 "id": "basic",
      //                 "name": "人员信息"
      //               },
      //               {
      //                 "id": "permit",
      //                 "name": "启用"
      //               },
      //               {
      //                 "id": "prohibit",
      //                 "name": "停用"
      //               },
      //               {
      //                 "id": "limittime",
      //                 "name": "定期停用"
      //               },
      //               {
      //                 "id": "lock",
      //                 "name": "锁定学工号"
      //               },
      //               {
      //                 "id": "unlock",
      //                 "name": "解锁学工号"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "account",
      //             "name": "学工号",
      //             "children": [
      //               {
      //                 "id": "add",
      //                 "name": "新增/导入"
      //               },
      //               {
      //                 "id": "remove",
      //                 "name": "删除"
      //               },
      //               {
      //                 "id": "modify",
      //                 "name": "修改/导入"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "multifactor",
      //             "name": "双因素",
      //             "children": [
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "notification",
      //             "name": "修改消息提醒",
      //             "children": [
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "center",
      //             "name": "个人中心",
      //             "children": [
      //               {
      //                 "id": "totp",
      //                 "name": "TOTP",
      //                 "children": [
      //                   {
      //                     "id": "bind",
      //                     "name": "绑定"
      //                   },
      //                   {
      //                     "id": "unbind",
      //                     "name": "解绑"
      //                   }
      //                 ]
      //               },
      //               {
      //                 "id": "modify",
      //                 "name": "修改",
      //                 "children": [
      //                   {
      //                     "id": "password",
      //                     "name": "密码"
      //                   },
      //                   {
      //                     "id": "phone",
      //                     "name": "手机"
      //                   },
      //                   {
      //                     "id": "email",
      //                     "name": "邮箱"
      //                   }
      //                 ]
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "service",
      //     "name": "服务管理",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "add",
      //             "name": "新增"
      //           },
      //           {
      //             "id": "remove",
      //             "name": "删除"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改"
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "code",
      //     "name": "代码管理",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "department",
      //             "name": "组织机构",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               },
      //               {
      //                 "id": "add",
      //                 "name": "新增"
      //               },
      //               {
      //                 "id": "remove",
      //                 "name": "删除"
      //               },
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "usertype",
      //             "name": "人员类别",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               },
      //               {
      //                 "id": "add",
      //                 "name": "新增"
      //               },
      //               {
      //                 "id": "remove",
      //                 "name": "删除"
      //               },
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "tag",
      //     "name": "标签管理",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "add",
      //             "name": "新增"
      //           },
      //           {
      //             "id": "remove",
      //             "name": "删除"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改"
      //           },
      //           {
      //             "id": "filter",
      //             "name": "筛选器",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               },
      //               {
      //                 "id": "add",
      //                 "name": "新增"
      //               },
      //               {
      //                 "id": "remove",
      //                 "name": "删除"
      //               },
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "statistic",
      //     "name": "统计分析",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询",
      //             "children": [
      //               {
      //                 "id": "general",
      //                 "name": "系统概况"
      //               },
      //               {
      //                 "id": "facerecognition",
      //                 "name": "人脸识别"
      //               },
      //               {
      //                 "id": "login",
      //                 "name": "用户登录"
      //               },
      //               {
      //                 "id": "serviceaccess",
      //                 "name": "访问量"
      //               },
      //               {
      //                 "id": "ipaccess",
      //                 "name": "登录IP"
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "authorization",
      //     "name": "权限管理",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "add",
      //             "name": "新增"
      //           },
      //           {
      //             "id": "remove",
      //             "name": "删除"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改"
      //           },
      //           {
      //             "id": "role",
      //             "name": "角色",
      //             "children": [
      //               {
      //                 "id": "add",
      //                 "name": "新增"
      //               },
      //               {
      //                 "id": "remove",
      //                 "name": "删除"
      //               },
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               },
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "system",
      //     "name": "系统配置",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "basicsetting",
      //             "name": "基本配置",
      //             "children": [
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               },
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "securitypolicy",
      //             "name": "安全策略",
      //             "children": [
      //               {
      //                 "id": "passwordverify",
      //                 "name": "密码校验管理",
      //                 "children": [
      //                   {
      //                     "id": "query",
      //                     "name": "查询"
      //                   },
      //                   {
      //                     "id": "modify",
      //                     "name": "修改"
      //                   }
      //                 ]
      //               },
      //               {
      //                 "id": "accountsafety",
      //                 "name": "学工号安全参数",
      //                 "children": [
      //                   {
      //                     "id": "query",
      //                     "name": "查询"
      //                   },
      //                   {
      //                     "id": "modify",
      //                     "name": "修改"
      //                   }
      //                 ]
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "backup",
      //     "name": "备份与恢复",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "export",
      //             "name": "备份",
      //             "children": [
      //               {
      //                 "id": "execute",
      //                 "name": "执行"
      //               },
      //               {
      //                 "id": "record",
      //                 "name": "记录",
      //                 "children": [
      //                   {
      //                     "id": "query",
      //                     "name": "查询"
      //                   },
      //                   {
      //                     "id": "download",
      //                     "name": "下载"
      //                   },
      //                   {
      //                     "id": "remove",
      //                     "name": "删除"
      //                   }
      //                 ]
      //               }
      //             ]
      //           },
      //           {
      //             "id": "import",
      //             "name": "恢复",
      //             "children": [
      //               {
      //                 "id": "execute",
      //                 "name": "执行"
      //               },
      //               {
      //                 "id": "record",
      //                 "name": "记录",
      //                 "children": [
      //                   {
      //                     "id": "query",
      //                     "name": "查询"
      //                   },
      //                   {
      //                     "id": "remove",
      //                     "name": "删除"
      //                   }
      //                 ]
      //               },
      //               {
      //                 "id": "external",
      //                 "name": "外部数据",
      //                 "children": [
      //                   {
      //                     "id": "execute",
      //                     "name": "执行"
      //                   }
      //                 ]
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "notification",
      //     "name": "全局消息提醒配置",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改"
      //           },
      //           {
      //             "id": "service",
      //             "name": "服务",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               },
      //               {
      //                 "id": "add",
      //                 "name": "新增"
      //               },
      //               {
      //                 "id": "remove",
      //                 "name": "删除"
      //               },
      //               {
      //                 "id": "modify",
      //                 "name": "修改"
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "log",
      //     "name": "日志",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "audit",
      //             "name": "审计日志",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "login",
      //             "name": "登录日志",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "facerecognition",
      //             "name": "人脸识别日志",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "publish",
      //     "name": "资讯发布",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "add",
      //             "name": "新增"
      //           },
      //           {
      //             "id": "remove",
      //             "name": "删除"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改"
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "task",
      //     "name": "任务管理",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "add",
      //             "name": "新增"
      //           },
      //           {
      //             "id": "remove",
      //             "name": "删除"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改"
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     "id": "app",
      //     "name": "应用管理",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "id": "all",
      //         "name": "全部",
      //         "children": [
      //           {
      //             "id": "query",
      //             "name": "查询"
      //           },
      //           {
      //             "id": "add",
      //             "name": "新增"
      //           },
      //           {
      //             "id": "remove",
      //             "name": "删除"
      //           },
      //           {
      //             "id": "modify",
      //             "name": "修改"
      //           },
      //           {
      //             "id": "statistic",
      //             "name": "统计",
      //             "children": [
      //               {
      //                 "id": "query",
      //                 "name": "查询"
      //               }
      //             ]
      //           }
      //         ]
      //       }
      //     ]
      //   }
      // ]
    }
  },
  created () {
    this.getAuthorizationCode()
  },
  mounted () {
    this.domHeight = document.body.clientHeight - 500
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}
.add-btn {
  padding-bottom: 10px;
}
.add-btn, .add-item {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.add-item {
  /*text-align: center;*/
  padding: 10px 0 10px 30px;
}
.add-item-box {
  width: 100%;
  height: 300px;
  overflow-y: auto;
}
</style>
